@import "../node_modules/bootstrap/scss/bootstrap";
@import "owlCarousel/owl.carousel";
@import "owlCarousel/owl.theme.default";

// variable scss
@import "partials/variables";

// mixin scss
@import "partials/mixin";
@import "partials/keyframes";

// default scss
@import "partials/common";

// button scss
@import "partials/button";

// circle scss
@import "partials/circle";

/*============== body scss ===============*/
// menu scss
@import "partials/menu";
// banner scss
@import "partials/banner";
// about scss
@import "partials/about";
// widget area scss
@import "partials/widget";
// testimonial scss
@import "partials/testimonial";
// subscriber scss
@import "partials/subscriber";
// get-start-part scss
@import "partials/get-start-part";
// team-member scss
@import "partials/team-member";
// pricing scss
@import "partials/pricing";
// icon-hover-effects scss
@import "partials/icon-hover-effects";
// flip-boxes scss
@import "partials/flip-boxes";
// alert-boxes scss
@import "partials/alert-boxes";
// countdown scss
@import "partials/countdown";
// social-icons scss
@import "partials/social-icons";
// google-map scss
@import "partials/google-map";
// chart scss
@import "partials/chart";
// bullet-list scss
@import "partials/bullet-list";
// accordion scss
@import "partials/accordion";
// tab scss
@import "partials/tab";
// image-gallery scss
@import "partials/image-gallery";
// timeline scss
@import "partials/timeline";
// tooltip scss
@import "partials/tooltip";
// modal scss
@import "partials/modal";
// heading scss
@import "partials/heading";
// highlight-box scss
@import "partials/highlight-box";
// cards scss
@import "partials/cards";
// info-box scss
@import "partials/info-box";
// icon-box scss
@import "partials/icon-box";
// progress-bar scss
@import "partials/progress-bar";
// instagram-widget scss
@import "partials/instagram-widget";
// video-gallery scss
@import "partials/video-gallery";
// blockquote scss
@import "partials/blockquote";
// counter scss
@import "partials/counter";
// mobile-part scss
@import "partials/mobile-part";
// client-logo scss
@import "partials/client-logo";
// contact scss
@import "partials/contact";
// sidebar scss
@import "partials/sidebar";
// error-page scss
@import "partials/error-page";
// comment scss
@import "partials/comment";


// footer scss
@import "partials/footer";

// breadcrumb scss
@import "partials/breadcrumb";



