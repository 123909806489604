$theme-color: #2e3d62;
$theme-color-2: #42a5f5;
$theme-color-3: #1e88e5;
$theme-color-4: #7f8897;
$theme-color-5: #ffb02b;
$theme-color-6: #F7F9FB;
$theme-color-7: #9ea6ba;
$theme-color-8: #b99566;
$white: #fff;
$black: #000;
$color: #eee;
$color2: #ececec;
$color3: #f7f8fd;
$theme-font: 'Poppins', sans-serif;
$default-font: 1rem;
$default-font-2: 15px;
$default-font-3: 14px;
$default-font-4: 13px;
$default-font-5: 12px;
$primary-font: 45px;
$display: 100px;
$display-2: 90px;
$display-3: 80px;
$display-4: 70px;
$display-5: 60px;
$display-6: 50px;
$display-7: 40px;
$display-8: 30px;
$display-9: 35px;
$display-10: 28px;
$display-11: 25px;
$display-12: 24px;
$display-13: 22px;
$display-14: 20px;
$display-15: 18px;
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;
$alter-color: #42a5f5;;
$alter-color-2: #4BE6B8;
$alter-color-3: #F78C01;
$alter-color-4: #C500EE;
$alter-color-5: #F8C652;
$alter-color-6: #F55974;
$alter-color-7: #579657;
$alter-color-8: #3897F0;
$svg-bg: #fff;

$laptop_ls: 'only screen and (min-width: 320px) and (max-width: 1919px)';
$laptop_l: '(max-width: 1440px)';
$laptop_m: '(max-width: 1366px)';
$laptop_m_two: '(max-width: 1280px)';
$laptop_m_three: '(max-width: 1200px)';
$laptop_m_four: '(max-width: 1199px)';
$laptop_m_five: '(max-width: 1024px)';
$laptop_m_six: '(min-width: 1200px)';
$medium_device: 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
$tab_device-and-mobile: 'only screen and (min-width: 320px) and (max-width: 990px)';
$tab_device_two: '(max-width: 768px)';
$tab_device_three: '(max-width: 991px)';
$tab_device_four: '(min-width: 991px)';
$large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
$large_mobile_two: ' (max-width: 600px)';
$large_mobile_three: ' (max-width: 575px)';
$large_mobile_four: ' (max-width: 480px)';
$small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
$small_mobile_one: ' (max-width: 425px)';
$small_mobile_two: '(max-width: 400px)';
$small_mobile_three: '(max-width: 320px)';
$small_mobile_four: '(max-width: 375px)';
$device-1600: '(max-width: 1600px)';
$device-767: '(max-width: 767px)';
$device-992: '(max-width: 992px)';
$device-767: '(max-width: 767px)';
$device-481: '(max-width: 481px)';
$device-384: '(max-width: 384px)';