/*-===============================
    banner-area
===============================-*/
.banner-area {
  position: relative;
  overflow: hidden;
  .banner-svg {
    position: absolute;
    top: 0;
    left: 0;
    fill: $theme-color-6;
    height: 112%;
  }
}
.banner-item-wrap {
  position: relative;
  background-position: center center;
  background-size: cover;
  padding-top: 250px;
  padding-bottom: 120px;
  z-index: 2;
  @media #{$large_mobile} {
    padding-top: 200px;
    padding-bottom: 50px;
  }
  @media #{$small_mobile} {
    padding-top: 200px;
    padding-bottom: 50px;
  }
}
.banner-item-table {
  display: table;
  width: 100%;
  height: 100%;
  .banner-item-tablecell {
    display: table-cell;
    vertical-align: middle;
  }
}
.banner-content {
  position: relative;
  z-index: 2;
  .section-heading {
    .section__meta {
      letter-spacing: 4px;
      color: $theme-color;
      background-color: rgba(46, 61, 98, 0.1);
      padding: 4px 14px;
      margin-bottom: 25px;
    }
    .section__title {
      font-size: $display-4;
      line-height: 80px;
      font-weight: $font-weight-bold;
      margin-bottom: 36px;
      @media #{$large_mobile} {
        font-size: $display-6;
        line-height: 60px;
      }
      @media #{$large_mobile_three} {
        font-size: $primary-font;
        line-height: 55px;
      }
      @media #{$small_mobile_one} {
        font-size: $display-7;
        line-height: 50px;
      }
      br {
        @media #{$small_mobile_four} {
          display: none;
        }
      }
    }
  }
  .section-description {
    .section__desc {
      font-size: $display-12;
      line-height: 37px;
      @media #{$large_mobile_three} {
        font-size: $display-14;
      }
      br {
        @media #{$tab_device} {
          display: none;
        }
        @media #{$large_mobile} {
          display: none;
        }
        @media #{$small_mobile} {
          display: none;
        }
      }
    }
  }
  .btn-box {
    a {
      @media #{$small_mobile_one} {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
}

/*-===============================
    home-page-4
===============================-*/
.home-page-4 {
  .banner-item-wrap {
    background-image: url("../images/banner-home-img.jpg");
    padding-top: 120px;
    padding-bottom: 80px;
    @media #{$large_mobile_four} {
      padding-top: 250px;
    }
    .line-bg {
      @include transform(rotate(0deg));
      &:before {
        display: none;
      }
    }
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $theme-color;
      opacity: 0.9;
      z-index: -1;
    }
  }
  .banner-content {
    .section-heading {
      .section-icon {
        &:before {
          color: rgba(255, 255, 255, 0.5);
          left: 0;
        }
        &:after {
          color: rgba(255, 255, 255, 0.4);
          right: 8px;
        }
      }
      .section__title {
        color: $white;
        margin-bottom: 20px;
      }
    }
    .section-description {
      .section__desc {
        color: $white;
        font-size: $display-14;
      }
    }
    .btn-box {
      -ms-flex-pack: start;
      justify-content: flex-start;
      @media #{$small_mobile_one} {
        width: 210px;
        text-align: left;
      }
      .contact-number {
        color: $white;
        margin-left: 20px;
        border-bottom: 1px solid $white;
        padding-bottom: 2px;
        @media #{$small_mobile_one} {
          margin-left: 0;
          margin-top: 20px;
          display: inline-block;
        }
        span {
          font-weight: $font-weight-medium;
        }
      }
    }
  }
  .preview-video {
    position: absolute;
    bottom: 60px;
    right: 80px;
    text-align: center;
  }
}
/*-===============================
    home-page-5
===============================-*/
.slider-circle-shape {
  width: 600px;
  height: 600px;
  position: absolute;
  display: block;
  border: 100px dotted rgba(255, 255, 255, 0.5);
  opacity: 0.1;
  @include border-radius(50%);
  z-index: 1;
}
.slider-circle-shape1 {
  top: -200px;
  left: -230px;
  border: 100px dashed rgba(255, 255, 255, 0.5);
}
.slider-circle-shape2 {
  bottom: -210px;
  right: -210px;
}
.slider-circle-shape1,
.slider-circle-shape2 {
  animation: spinme 11000ms infinite linear;
}

.info-contact-box {
  margin-top: 200px;
  background: #7f8897;
  background: -webkit-linear-gradient(to right, #7f8897, #A3B0BA);
  background: linear-gradient(to right, #7f8897, #A3B0BA);
  text-align: left;
  position: relative;
  z-index: 2;
  @media #{$large_mobile} {
    margin-top: 140px;
  }
  @media #{$small_mobile} {
    margin-top: 140px;
  }
}
.banner-contact-list {
  .col-lg-4 {
    &:last-child {
      .banner-contact-item {
        border-right: none;
      }
    }
  }
}
.banner-contact-item {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  padding: 50px 0;
  @media #{$tab_device} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media #{$large_mobile} {
    padding-top: 30px;
    padding-bottom: 30px;
    border-right: none;
  }
  @media #{$small_mobile} {
    padding-top: 30px;
    padding-bottom: 30px;
    border-right: none;
  }
  span {
    width: 60px;
    height: 60px;
    line-height: 60px;
    background-color: rgba(255, 255, 255, 0.1);
    @include border-radius(50%);
    font-size: $display-10;
    float: left;
    margin-right: 20px;
    text-align: center;
    position: relative;
    @include transition(0.3s);
    &:after {
      position: absolute;
      content: '';
      right: -6px;
      top: 50%;
      @include transform(translateY(-50%));
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid rgba(255, 255, 255, 0.1);
      @include transition(0.3s);
    }
  }
  .banner-contact__title {
    color: $white;
    font-size: $display-13;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .banner-contact__text {
    color: rgba(255, 255, 255, 0.7);
    a {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  &:hover {
    span {
      background-color: $white;
      color: $theme-color;
      &:after {
        border-left-color: $white;
      }
    }
  }
}





